import React from "react";
import { Link } from "gatsby";
import { Link as ScrollLink } from "react-scroll";
import { Container, Row, Col } from "react-bootstrap";
import logoLight from "../assets/images/logo-light.png";
import blogPost1 from "../assets/images/resources/footer-img-1-1.jpg";
import blogPost2 from "../assets/images/resources/footer-img-1-2.jpg";

const Footer = () => {
  return (
    <section className="site-footer">
      <div className="main-footer pt-142 pb-80">
        <Container>
          <Row>
            <Col className="col-1-foot col-2-foot">
              <div className="footer-widget mb-40 footer-widget__about">
                <Link to="/">
                  <img
                    src={logoLight}
                    className="footer-widget__logo"
                    max-width="300px"
                    alt=""
                  />
                </Link>
	  <p>Wysoka jakość obsługi i świadczonych usług </p>
                <ul className="list-unstyled footer-widget__contact">
	  <li>
                    <a href="tel:32 292 71 45">
                      <i className="fas fa-phone"></i>32 292 71 45
                    </a>
                  </li>
                  <li>
                    <a href="tel:+48 501 483 860">
                      <i className="fas fa-mobile"></i>+48 501 483 860
                    </a>
                  </li>
                  <li>
                    <a href="mailto:kontakt@serwisjatkowski.pl">
                      <i className="azino-icon-email"></i>kontakt@serwisjatkowski.pl
                    </a>
                  </li>
                  <li>
                    <a href="#none">
                      <i className="azino-icon-pin"></i>Sosnowiec - Kazimerz, ul. Morcinka 17
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
       
             <Col className="col-1-foot col-2-foot">
              
               <div className="footer-widget footer-widget__link mb-40">
                <h3 className="footer-widget__title">Menu</h3>
                <ul className="list-unstyled footer-widget__link-list">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">O Nas</Link>
                  </li>
         
                  <li>
                    <Link to="/causes">Usługi</Link>
                  </li>
	                    <li>
                    <Link to="/gallery">Nasz warsztat</Link>
                  </li>

                  <li>
                    <Link to="/contact">Kontakt</Link>
	  </li>
                </ul>
              
              </div>
            </Col>
 
          </Row>
        </Container>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <ScrollLink
            to="wrapper"
            smooth={true}
            duration={500}
            className="scroll-to-top"
          >
            <i className="far fa-angle-up"></i>
          </ScrollLink>
          <p>Wszystkie prawa zastrzeżone © 2021 Auto Serwis - Tomasz Jatkowski | Deisnged by <a className="foot-link" href="https://forest-design.pl" target="_blank">ForestDESIGN</a></p>
          
        </div>
      </div>
    </section>
  );
};

export default Footer;
