import React from "react";
import { Link } from "gatsby";

const NavLinks = ({ extraClassName }) => {
  const handleDropdownStatus = (e) => {
    let clickedItem = e.currentTarget.parentNode;
    clickedItem.querySelector(".dropdown-list").classList.toggle("show");
  };
  return (
    <ul className={`main-menu__list ${extraClassName}`}>
     
 <li className="active-link">
        <Link to="/" activeClassName="active">Home</Link>
      </li>
<li className="active-link">
  <Link to="/about" activeClassName="active">O Nas</Link>
      </li>

 <li className="active-link">
        <Link to="/causes" activeClassName="active">Usługi</Link>
      </li>

<li className="active-link">
            <Link to="/gallery" activeClassName="active">Nasz Warsztat</Link>
          </li>

<li className="active-link">
        <Link to="/contact" activeClassName="active">Kontakt</Link>
      </li>

      
      
      
{/*<li className="search-btn search-toggler">
        <span>
          <i className="azino-icon-magnifying-glass"></i>
        </span>
      </li>*/}
    </ul>
  );
};

export default NavLinks;
